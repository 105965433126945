
import Vue from "vue";
import notification from "@/services/notificationService";
export default Vue.extend({
  name: "Logbook",
  data() {
    return {
      selectedDateTimeTab: "period",
      selectedVehicles: [] as string[],
      filters: {
        dateRangeStart: undefined as undefined | Date,
        dateRangeEnd: undefined as undefined | Date,
        timePeriod: "thisDay"
      },
      downloadPreview: false
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    vehicles() {
      return this.$store.state.vehicles;
    },
    logbookUrl() {
      return this.$store.state.logbookUrl;
    }
  },
  watch: {
    selectedVehicles: function() {
      this.downloadPreview = false;
      this.$store.dispatch("setLogbookUrl", undefined);
    },
    selectedDateTimeTab: function() {
      if (this.selectedDateTimeTab === "period") {
        this.filters.dateRangeStart = undefined;
        this.filters.dateRangeEnd = undefined;
      } else {
        this.filters.timePeriod = "thisDay";
      }
    },
    filters: {
      handler() {
        this.downloadPreview = false;
        this.$store.dispatch("setLogbookUrl", undefined);
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch("getVehicles");
  },
  methods: {
    clearDateRange() {
      this.filters.dateRangeStart = undefined;
      this.filters.dateRangeEnd = undefined;
    },
    generateLogbook() {
      if (this.selectedVehicles.length === 0) {
        notification.warning("No vehicle selected");
      } else {
        if (this.selectedDateTimeTab === "period") {
          this.$store
            .dispatch("getLogbookUrl", {
              mode: this.selectedDateTimeTab,
              vehicles: this.selectedVehicles,
              timePeriod: this.filters.timePeriod
            })
            .then(() => {
              this.downloadPreview = true;
            });
        } else if (this.selectedDateTimeTab === "range") {
          this.$store
            .dispatch("getLogbookUrl", {
              mode: this.selectedDateTimeTab,
              vehicles: this.selectedVehicles,
              startDate: this.filters.dateRangeStart,
              endDate: this.filters.dateRangeEnd
            })
            .then(() => {
              this.downloadPreview = true;
            });
        }
      }
    }
  }
});
